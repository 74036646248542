import React, { useMemo } from "react"
import { As, Box, Container, Flex, Heading, Link, Text } from "@chakra-ui/react"

import { AnalyticProps, withSection } from "@app/hoc/Section"
import { useRoutes } from "@app/hooks/useRoutes"
import { useShopify } from "@app/hooks/useShopify"
import ButtonWithIcon from "@app/components/Input/ButtonWithIcon"
import ProductsCarouselItems from "@app/components/Sections/ProductsCarousel/ProductsCarouselItems"

type Props = GatsbyTypes.SanitySectionProductsCarousel & AnalyticProps

const FeaturedProducts: React.FC<Props> = ({ title, description, link, products, innerRef, handleTrackingClick, tag }) => {
  const { productNormaliser } = useShopify()
  const { urlResolver } = useRoutes()
  const id = Date.now().toString()
  const customLink = urlResolver(link)

  const items = useMemo(
    () =>
      products?.map(p => {
        const shopifyData = p?.shopify?.raw ? JSON.parse(p.shopify.raw) : null
        if (shopifyData) return productNormaliser(shopifyData)

        return productNormaliser(p)
      }),
    [productNormaliser, products]
  )

  return (
    <Box as="section" ref={innerRef}>
      <Container maxW="container.4xl" px={{ base: "4", lg: "20" }} py={{ base: "8", lg: "18" }} bg="background.white">
        <Box textAlign="center" mb={{ base: "5", lg: "10" }}>
          {title && (
            <Heading as={tag as As} size="h3" mb={{ base: "2", lg: "3" }}>
              {title}
            </Heading>
          )}

          {description && <Text size="paragraphLarge">{description}</Text>}
        </Box>

        <ProductsCarouselItems id={id} items={items} />

        {link && (
          <Flex justifyContent="center">
            <ButtonWithIcon
              as={Link}
              href={customLink.url}
              isExternal={customLink.external}
              onClick={handleTrackingClick}
              variant="solidSecondary"
              iconName="arrows/chevron-right"
              w="full"
              maxW={{ base: "container.sm", lg: "57.5" }}
            >
              {customLink.title}
            </ButtonWithIcon>
          </Flex>
        )}
      </Container>
    </Box>
  )
}

export default React.memo(withSection(FeaturedProducts))
